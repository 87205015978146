var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _vm.switchWarning
        ? _c(
            "v-alert",
            { staticClass: "mt-5", attrs: { type: "warning", text: "" } },
            [_vm._v(_vm._s(_vm.switchWarning))]
          )
        : _vm._e(),
      _c("v-text-field", {
        staticClass: "first-name-field",
        attrs: {
          autofocus: "",
          counter: 50,
          label: "First Name",
          rules: [_vm.rules.nonEmpty],
          required: ""
        },
        model: {
          value: _vm.firstName,
          callback: function($$v) {
            _vm.firstName = $$v
          },
          expression: "firstName"
        }
      }),
      _c("v-text-field", {
        staticClass: "last-name-field",
        attrs: {
          counter: 50,
          label: "Last Name",
          rules: [_vm.rules.nonEmpty],
          required: ""
        },
        model: {
          value: _vm.lastName,
          callback: function($$v) {
            _vm.lastName = $$v
          },
          expression: "lastName"
        }
      }),
      _c("v-text-field", {
        staticClass: "password-first-field",
        attrs: {
          counter: 50,
          label: "Password",
          required: "",
          type: "password",
          rules: _vm.password.length
            ? [
                _vm.rules.passwordHasNoFirstSpecialCharacter,
                _vm.rules.passwordAllowedCharacters,
                _vm.rules.passwordLength,
                _vm.rules.passwordHasUppercase,
                _vm.rules.passwordHasLowercase,
                _vm.rules.passwordHasNumber,
                _vm.rules.passwordHasSpecialCharacter
              ]
            : [_vm.rules.nonEmpty]
        },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _c("v-text-field", {
        staticClass: "password-second-field",
        attrs: {
          counter: 50,
          label: "Verify Password",
          required: "",
          type: "password",
          rules: [_vm.rules.passwordMatch, _vm.rules.passwordLength]
        },
        model: {
          value: _vm.password2,
          callback: function($$v) {
            _vm.password2 = $$v
          },
          expression: "password2"
        }
      }),
      _c("v-text-field", {
        attrs: { label: "E-mail", required: "", readonly: "" },
        model: {
          value: _vm.email,
          callback: function($$v) {
            _vm.email = $$v
          },
          expression: "email"
        }
      }),
      _c(
        "v-btn",
        {
          attrs: {
            color: "success",
            disabled: !_vm.valid || _vm.creatingAccount,
            loading: _vm.creatingAccount,
            width: "100%"
          },
          on: { click: _vm.createAccount }
        },
        [_vm._v("create account")]
      ),
      _c("TheInvitationModal", {
        attrs: { message: "Creating account", show: _vm.creatingAccount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }