<template>
    <v-form ref="form" v-model="valid">
        <v-alert class="mt-5" v-if="switchWarning" type="warning" text>{{ switchWarning }}</v-alert>
        <v-text-field autofocus class="first-name-field" v-model="firstName" :counter="50" label="First Name" :rules="[rules.nonEmpty]" required></v-text-field>
        <v-text-field class="last-name-field" v-model="lastName" :counter="50" label="Last Name" :rules="[rules.nonEmpty]" required></v-text-field>
        <v-text-field
            class="password-first-field"
            v-model="password"
            :counter="50"
            label="Password"
            required
            type="password"
            :rules="
                password.length
                    ? [
                          rules.passwordHasNoFirstSpecialCharacter,
                          rules.passwordAllowedCharacters,
                          rules.passwordLength,
                          rules.passwordHasUppercase,
                          rules.passwordHasLowercase,
                          rules.passwordHasNumber,
                          rules.passwordHasSpecialCharacter
                      ]
                    : [rules.nonEmpty]
            "
        ></v-text-field>
        <v-text-field
            class="password-second-field"
            v-model="password2"
            :counter="50"
            label="Verify Password"
            required
            type="password"
            :rules="[rules.passwordMatch, rules.passwordLength]"
        ></v-text-field>
        <v-text-field v-model="email" label="E-mail" required readonly></v-text-field>
        <v-btn color="success" :disabled="!valid || creatingAccount" :loading="creatingAccount" @click="createAccount" width="100%">create account</v-btn>
        <TheInvitationModal message="Creating account" :show="creatingAccount" />
    </v-form>
</template>
<script>
import { defaultAccountType } from '@/utils'
import store from 'store'
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    name: 'TheEmailSignup',
    props: {
        componentSpecificProps: {
            type: Object,
            default: function() {
                return {
                    userEmail: ''
                }
            }
        },
        recommended: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            password2: '',
            firstName: '',
            lastName: '',
            creatingAccount: false,
            rules: {
                passwordMatch: p => this.$data.password === p || 'Passwords do not match.',
                passwordAllowedCharacters: p =>
                    p.search(/[^a-zA-Z0-9!#$%^&().\-=_+:]/) <= -1 ||
                    'Password contains a not-allowed character. Allowed characters: a-z A-Z 0-9 ! # $ % ^ & ( ) . - = _ + :',
                passwordHasUppercase: p => p.search(/[A-Z]/) > -1 || 'Password must contain at least one uppercase letter.',
                passwordHasLowercase: p => p.search(/[a-z]/) > -1 || 'Password must contain at least one lowercase letter.',
                passwordHasNumber: p => p.search(/[0-9]/) > -1 || 'Password must contain at least one number.',
                passwordHasSpecialCharacter: p => p.search(/[!#$%^&().\-=_+:]/) > -1 || 'Password must contain at least one special character.',
                passwordLength: p => p.length >= 9 || 'Password needs to be at least 9 characters.',
                passwordHasNoFirstSpecialCharacter: p => p[0].search(/[!#$%^&().\-=_+:]/) === -1 || 'Password cannot start with special character.',
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            }
        }
    },
    computed: {
        switchWarning() {
            if (defaultAccountType(this.$data.email) === 'SWITCH') {
                return 'Based on your email address, please consider signing up with your SWITCH account using the above option.'
            }
            return ''
        }
    },
    methods: {
        createAccount() {
            this.$data.creatingAccount = true
            store.set('nvLastEmail', this.$data.email)
            this.$axios
                .post(
                    `/users/register_with_invitation/auth0/${this.$route.params.invid}`,
                    {
                        email: this.$data.email,
                        family_name: this.$data.lastName,
                        given_name: this.$data.firstName,
                        password: this.$data.password
                    },
                    { timeout: 300000 }
                )
                .then(() => {
                    this.$data.creatingAccount = false
                    this.$nextTick(() => {
                        this.$auth.passwordLogin(
                            this.$data.email,
                            this.$router.resolve({
                                name: 'invitation-accept',
                                params: { invid: this.$route.params.invid }
                            }).href
                        )
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.$data.creatingAccount = false
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account creation failed',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        }
    },
    mounted() {
        this.$data.email = this.$props.componentSpecificProps.userEmail
    },
    components: { TheInvitationModal }
}
</script>
